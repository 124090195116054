<template>
  <div>
    <b-modal
      id="delete-modal-category-new"
      hide-header
      hide-footer
      :visible="isshowModal"
    >
      <div class="d-block text-center">
        <h5>{{ title_modal }}</h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="ScreenLogin()"
        >
          ログイン画面へ
        </b-button>
      </div>
    </b-modal>
    <div>
      <b-modal id="active-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            すでに同システム利用の他サイトにて、登録済みです。
            ログイン情報を共通化させますか？
            （登録不要で、すぐログインが可能です。
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="confirmAccount()"
            >受け入れる</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="closeModal()"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { mapActions } from "vuex";
export default {
  name: "TwitterLogin",
  data() {
    return {
      isshowModal: false,
      token: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      title_modal: null,
      dataRequestLogin: null,
    };
  },
  created() {
    const { query } = this.$route;
    const shop_id = this.shop_id;
    Api.userRequestServer
      .get(
        `/auth/callback/twitter?user=${query.user}&oauth_token=${query.oauth_token}&oauth_verifier=${query.oauth_verifier}&shop_id=${shop_id}`
      )
      .then((response) => {
        const { data } = response;
        if (data.success) {
          if (data.data.active_token) {
            this.dataActiveToken = data.data.active_token;
            this.dataRequestLogin = data;
            this.$bvModal.show("active-modal-page");
          } else {
            const { access_token, user, expires_in } = data.data;
            const expires_at = new Date(
              new Date().setMinutes(new Date().getMinutes() + expires_in)
            );
            const { user_type } = user;
            localStorage.setItem(Constants.TOKEN_USER, access_token);
            localStorage.setItem(Constants.USER_TYPE_USER, user_type);
            localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
            localStorage.setItem(Constants.USER_ID, data.data.user.user_id);
            localStorage.setItem(
              Constants.NORMAL_USER_INFO,
              JSON.stringify(user)
            );
            this.$router.push({
              name: this.$route.params.shopId ? "library" : "library domain",
              params: { shopId: shop_id },
            });
          }
        } else {
          this.title_modal = data.message;
          this.isshowModal = true;
          this.token = data.data.slag_token;
          this.shopId = data.data.shop_id;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    ...mapActions({
      activeAccount: "activeAccount",
    }),
    ScreenLogin() {
      if (this.token && this.token !== "undefined" && this.token !== "") {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shop_id },
          query: { token: this.token },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shop_id },
        });
      }
    },
    async confirmAccount() {
      const shop_id = localStorage.getItem(Constants.SHOP_ID);
      const dataRequest = {
        token: this.dataActiveToken,
      };
      await this.activeAccount(dataRequest);
      this.$bvModal.hide("active-modal-page");
      const { access_token, user, expires_in } = this.dataRequestLogin.data;
      const { user_type } = user;
      const expires_at = new Date(
        new Date().setMinutes(new Date().getMinutes() + expires_in)
      );
      localStorage.setItem(Constants.TOKEN_USER, access_token);
      localStorage.setItem(Constants.USER_TYPE_USER, user_type);
      localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
      localStorage.setItem(Constants.USER_ID, user.user_id);
      localStorage.setItem(Constants.EMAIL_USER, user.email);
      localStorage.setItem(Constants.NORMAL_USER_INFO, JSON.stringify(user));
      localStorage.setItem(Constants.IS_REMEMBER_USER, false);
      this.$router.push({
        name: this.$route.params.shopId ? "library" : "library domain",
        params: { shopId: shop_id },
      });
    },
  },
};
</script>
